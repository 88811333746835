<template>
<section id="livestatus" class="hero is-large has-text-centered">
  <!-- HEADER HALO LOGO -->
  <div class="hero-head">
    <img src="./../assets/gll.png" width="10%"/>
    <!-- <div class="field" >
      <label class="label stblue-foreground calibri font-size-200 m-t-12">{{headline}}</label>
    </div> -->
  </div>
  <div class="column is-half is-offset-one-quarter">
    <!-- LOGIN BOX -->
    <div class="box needs-padding is-border-green has-background-white" v-if="!logged">
      <div class="container">
        <div class="container">
          <article class="message is-danger" v-if="logerror">
            <div class="message-header">
              <p>Authentication Error</p>
              <button class="delete" aria-label="error" @click="closeLogErrorMsg"></button>
            </div>
            <div class="message-body">
              The username and password do not match with our records
            </div>
          </article>
          <!-- <form> -->
            <div class="field">
              <div class="control">
                <input class="input" type="text" v-model="user" placeholder="Username" v-on:keyup="validateLogin"/>
              </div>
            </div>
            <div class="field" >
              <div class="control">
                <input class="input" type="password" v-model="pass" placeholder="Password" v-on:keyup="validateLogin"/>
              </div>
            </div>
            <button class="button has-text-white-ter stblue-background is-rounded calibri is-medium m-t-25 calibri font-size-200" @click="login"> Sign in </button>
          <!-- </form> -->
        </div>
        </div>
        <!-- <div class="field">
          <label class="label has-text-white-bis" v-text="decoded"/>
        </div> -->
    </div>
    <!-- GREY BOX NAV BAR -->
    <div class="box needs-padding is-border-green has-background-grey" v-if="logged & showlog">
      <div class="container">
        <div class="columns is-vcentered is-mobile">
            <div class="column is-one-tenth">  
              <a class="calibri font-size-150" title="Back to clients" @click="showcli = true; showlog = false;">
                <font-awesome-icon icon="arrow-left"/>
              </a>
            </div>
            <div class="column is-one-fifth">
            <!-- <label class="label is-info has-text-white">Filter by name:</label> -->
              <input class="input" type="text" v-model="logfilters.member.value" placeholder="Member"/>
            </div>
            <div class="column is-one-fifth">
            <!-- <label class="label is-info has-text-white">Filter by name:</label> -->
              <input class="input" type="text" v-model="logfilters.scanType.value" placeholder="Scan Type"/>
            </div>
            <div class="column is-one-fifth">
            <!-- <label class="label is-info has-text-white">Filter by name:</label> -->
              <input class="input" type="text" v-model="logfilters.response.value" placeholder="Response"/>
            </div>
            <div class="column is-one-fifth">
            <!-- <label class="label is-info has-text-white">Filter by name:</label> -->
              <input class="input" type="text" v-model="logfilters.relay.value" placeholder="Relay"/>
            </div>
            <div class="column is-one-tenth">
              <a class="calibri font-size-150" title="Sign out" @click="logOut">
                <font-awesome-icon icon="sign-out-alt"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    <div class="box needs-padding is-border-green has-background-grey" v-if="logged & showloc">
      <div class="container">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-one-half">
                <!-- <label class="label is-info has-text-white">Filter by name:</label> -->
                <input class="input" type="text" v-model="filters.name.value" placeholder="Filter by name"/>
          </div>
          <!-- <div class="column is-one-quarter">
            <datepicker v-model="initdate" calendar-class="calendar" input-class="input" v-if="showloc"></datepicker>
          </div>
          <div class="column is-one-quarter">
            <datepicker v-model="enddate" calendar-class="calendar" input-class="input" v-if="showloc"></datepicker>
          </div> -->
          <div class="column is-one-quarter">
            <div class="columns is-vcentered is-mobile">
              <div class="column is-one-half">
              <!-- <button class="button has-text-white-ter stblue-background is-rounded is-medium m-t-25 " @click="exportLog"> Export </button> -->
              </div>
              <div class="column is-one-half">
                <a class="calibri font-size-150" title="Sign out" @click="logOut">
                  <font-awesome-icon icon="sign-out-alt"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Locations TABLE BOX -->
    <div class="box needs-padding is-border-green has-background-white" v-if="logged & showloc">
      <div class="container">
        <div class="table__wrapper">
          <v-table class="table" title="Locations" :data="locations" 
          :filters="filters" :currentPage.sync="currentPage" :pageSize="20"
          @totalPagesChanged="totalPages = $event">
            <thead slot="head">
              <th>View Logs</th>
              <v-th sortKey="name" defaultSort="asc">Name</v-th>
            </thead>
            <tbody slot="body" slot-scope="{displayData}">
              <tr v-for="row in displayData" :key="row.id">
                <td>            
                  <a class="calibri font-size-100" @click="retrieveClients(row.id)">
                    <font-awesome-icon icon="eye" title="View this location logs" />
                  </a>
                </td>
                <td>{{row.name}}</td>
                <!-- <td>{{row.memberId}}</td> -->
                <!-- <td>{{row.temporal ? "Yes" : "No"}}</td> -->
              </tr>
            </tbody>
          </v-table>
          <smart-pagination
            :currentPage.sync="currentPage"
            :totalPages="totalPages"
          />
        </div>
      </div>
    </div>


 <div class="box needs-padding is-border-green has-background-grey" v-if="logged & showcli">
      <div class="container">
        <div class="columns is-vcentered is-mobile">
          <div class="column is-one-quarter">
            <a class="calibri font-size-150" title="Back to locations" @click="showloc = true; showcli = false;">
              <font-awesome-icon icon="search-location"/>
            </a>
          </div>
          <div class="column is-one-quarter">
            <!-- <label class="label is-info has-text-white">Report date:</label> -->
            <datepicker v-model="initdate" calendar-class="calendar" input-class="input" v-if="showcli"></datepicker>
          </div>
          <div class="column is-one-quarter">
            <!-- <label class="label is-info has-text-white">Report date:</label> -->
            <datepicker v-model="enddate" calendar-class="calendar" input-class="input" v-if="showcli"></datepicker>
          </div>
          <div class="column is-one-quarter">
            <div class="columns is-vcentered is-mobile">
              <div class="column is-one-half">
              <!-- <button class="button has-text-white-ter stblue-background is-rounded is-medium m-t-25 " @click="exportLog"> Export </button> -->
              </div>
              <div class="column is-one-half">
                <a class="calibri font-size-150" title="Sign out" @click="logOut">
                  <font-awesome-icon icon="sign-out-alt"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Clients TABLE BOX -->
    <div class="box needs-padding is-border-green has-background-white" v-if="logged & showcli">
      <div class="container">
        <div class="table__wrapper">
          <v-table class="table" title="Doormans" :data="clients" 
          :filters="filters" :currentPage.sync="currentPage" :pageSize="20"
          @totalPagesChanged="totalPages = $event">
            <thead slot="head">
              <th>View Logs</th>
              <v-th sortKey="name" defaultSort="asc">Name</v-th>
            </thead>
            <tbody slot="body" slot-scope="{displayData}">
              <tr v-for="row in displayData" :key="row.id">
                <td>            
                  <a class="calibri font-size-100" @click="retriveLogs(row.id);currentclient = row.name">
                    <font-awesome-icon icon="eye" title="View this location logs" />
                  </a>
                </td>
                <td>{{row.name}}</td>
                <!-- <td>{{row.memberId}}</td> -->
                <!-- <td>{{row.temporal ? "Yes" : "No"}}</td> -->
              </tr>
            </tbody>
          </v-table>
          <smart-pagination
            :currentPage.sync="currentPage"
            :totalPages="totalPages"
          />
        </div>
      </div>
    </div>

    <!-- LOGS TABLE BOX -->
    <div class="box needs-padding is-border-green has-background-white" v-if="logged & showlog">
      <h1 class="title stblue-foreground">{{currentclient}}</h1>
      <div class="container">
        <div class="table__wrapper">
          <v-table class="table" title="Logs" :data="logs" 
          :filters="logfilters" :currentPage.sync="currentPage" :pageSize="20"
          @totalPagesChanged="totalPages = $event">
            <thead slot="head">
              <v-th sortKey="member">Member</v-th>
              <v-th sortKey="scanType">Scan Type</v-th>
              <v-th sortKey="response">Response</v-th>
              <v-th sortKey="relay">Relay</v-th>
              <v-th sortKey="time" defaultSort="desc">Time</v-th>
            </thead>
            <tbody slot="body" slot-scope="{displayData}">
              <tr v-for="row in displayData" :key="row.id">
                <td>{{row.member}}</td>
                <td>{{row.scanType}}</td>
                <td>{{row.response}}</td>
                <td>{{row.relay}}</td>
                <td>{{convertdate(new Date(row.time))}}</td>
              </tr>
            </tbody>
          </v-table>
          <smart-pagination
            :currentPage.sync="currentPage"
            :totalPages="totalPages"
          />
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <div class="container">
      <div class="field" >
        <label class="stblue-foreground calibri"> © Copyright {{year}} CCTech Ltd | All Rights Reserved </label>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import axios from 'axios';
import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    Datepicker
  },
  data(){
    return{
      user: '',
      pass: '',
      token: '',
      logged: false,
      showloc: true,
      showlog: false,
      showcli: false,
      memberadmin: false,
      locations: [],
      clients: [], 
      membersCRM: [],
      logerror: false,
      dataerror: false,
      year: 2020,
      initdate: '',
      enddate: '',
      // headline: 'Welcome to GLL Flow Logs',
      exportmsg: 'No events found at selected date, please try another date.',
      exportempty: false,
      exporterror: false,
      location: {
        id: 0,
        name: "",
        customerid: 0,
        lms: 0,
        extra: "",
        status: false,
      },
      client: {
        id: 0,
        name: "",
        locationdid: 0,
        status: false,
        lms: 0,
      },
      types:[],
      selectedType: '',
      typeModel: 0,
      selectedTypeId: 0,
      logs:[],
      log: {
        id: 0,
        member: "",
        scanType: "",
        response: "",
        clientid: 0,
        time: '',
        relay: "",
      },
      visitorDatesBackup: [],
      url: "https://mfapi.smarttile.co.uk/",
      // url: "https://localhost:44358/",
      filters: { 
        name: {value: '', keys:['name']},

      },
      logfilters:{
        member: {value: '', keys:['member']},
        scanType: {value: '', keys:['scanType']},
        response: {value: '', keys:['response']},
        relay: {value: '', keys:['relay']},
      },
      currentPage: 1,
      totalPages: 0,
      loading: false,
      deletedUser: 0,
      undoButton: false,
      askpass: false,
      uguid: '',
      locid: 0,
      currentclient: '',
    };
  },
  created(){

    let dt = new Date();
    var date = dt.getFullYear()+'-'+(dt.getMonth()+1)+'-'+dt.getDate();
    this.initdate = date;
    this.enddate = date;
    this.year = dt.getFullYear();
  },
  methods:{
    convertdate(date){
      let minutes = date.getMinutes() > 9 ? date.getMinutes() : "0" +date.getMinutes();
      let formatted_date = date.getHours() + ":" + minutes + ":" + date.getSeconds() + " " + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
      return formatted_date;
    },
    validateLogin(e){
      if (e.keyCode === 13) {
        if(this.user !== '' && this.pass !== ''){
          this.login();
        }
      }
    },
    login(){
      const auth = {
        headers: {token:'56u589gf648745!ji32'} 
      }
      // if(this.askpass){
      axios.get(this.url + "api/gllflowlogs/login?user=" + this.user + "&password=" + this.pass, auth)
        .then((res) => {
          if(res.status === 200){
            if(res.data === null){
              this.logerror = true;
            }
            else{
              this.token = res.data;
              this.showLocations();
            }
          }
          else{
            this.logerror = true;            
          }
        }).catch((error) => {
          console.log(error);
          this.logerror = true;
          // this.error = "Error at getting your passes"
      })
      // }
      // else{

      // }
    },
    showLocations(){
      this.logged = true;
      // this.headline = 'Locations';
      this.retriveLocations();
    },
    retriveLocations(){
      axios.get(this.url + "api/gllflowlogs/getlocations?token=" + this.token)
      .then((res) => {
        if(res.status === 200){
          if(res.data === null){
            this.dataerror = true;
          }
          else{
            this.locations = res.data;
            this.showloc = true;
            this.showlog = false;
          }
        }
        else{
          this.dataerror = true;            
        }
      }).catch((error) => {
        console.log(error);
        this.logerror = true;
        // this.error = "Error at getting your passes"
      })
    },
    retrieveClients(locid){
      axios.get(this.url + "api/gllflowlogs/getclients?token=" + this.token + "&loc=" + locid)
      .then((res) => {
        if(res.status === 200){
          if(res.data === null){
            this.dataerror = true;
          }
          else{
            this.clients = res.data;
            this.locid = locid;
            this.showloc = false;
            this.showcli = true;
          }
        }
        else{
          this.dataerror = true;            
        }
      }).catch((error) => {
        console.log(error);
        this.logerror = true;
        // this.error = "Error at getting your passes"
      })
    },
    retriveLogs(locid){
      let dindt = new Date(this.initdate);
      let indt = dindt.getFullYear()+'-'+(dindt.getMonth()+1)+'-'+dindt.getDate();
      let dendt = new Date(this.enddate);
      let endt = dendt.getFullYear()+'-'+(dendt.getMonth()+1)+'-'+dendt.getDate();
      axios.get(this.url + "api/gllflowlogs/GetScanLogs?token=" + this.token + "&cli=" + locid + "&init=" + indt + "&end=" + endt)
      .then((res) => {
        if(res.status === 200){
          if(res.data === null){
            this.dataerror = true;
          }
          else{
            this.logs = res.data;
            this.showcli = false;
            this.showlog = true;
          }
        }
        else{
          this.dataerror = true;            
        }
      }).catch((error) => {
        console.log(error);
        this.logerror = true;
        // this.error = "Error at getting your passes"
      })
    },
    exportLog(){
      this.loading = true;
      this.logged = true;
        // let strdate = this.exdate.getFullYear() + '-' + ("0" + (this.exdate.getMonth() + 1)).slice(-2) + '-' + this.exdate.getDate();
        let strdate = this.exdate.getFullYear() + '-' + this.getTwoDigitDateFormat(this.exdate.getMonth()+1) + '-' + this.getTwoDigitDateFormat(this.exdate.getDate());
        // console.log(strdate)
        let url = this.url + "api/stplus/GetCSMmembersLog?token=" + this.token + "&date=" + strdate;
        axios.get(url)
        .then((res) => {
          if(res.status === 200){
            window.open(url,"_self")
            this.loading = false;
          }
          else if(res.status === 204){
            this.exportmsg = 'No events found at selected date, please try another date.';
            this.exportempty = true;
            this.loading = false;
          }
        }).catch((error) => {
          console.log(error);
          this.logerror = true;
          // this.error = "Error at getting your passes"
          this.exportmsg = 'Error at generating the report file. Please check the date is correct or refresh the page and log in again.';
          this.exporterror = true;
          this.loading = false;
      })
    },
    getTwoDigitDateFormat(monthOrDate) {
      return (monthOrDate < 10) ? '0' + monthOrDate : '' + monthOrDate;
    },
    logOut(){
      window.location.reload();
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #76d35c;
}
font-awesome-icon{
  color: #76d35c;
}
.stblue-background {
  background-color: #55a440;
}
.stblue-foreground{
  color: #55a440;
}
.white-background{
  background-color: #fff; 
}
.calibri {
  font-family: Calibri, 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
  font-weight: normal;
}
.font-size-200{
  font-size: 200%;
}
.font-size-150{
  font-size: 150%;
}
.calibri-bold{
  font-weight: bold;
}
.m-t-25{
  margin: 25px;
}
.m-t-12{
  margin: 12px;
}
.table {
      margin-left: auto;
      margin-right: auto;
    }
    .table__wrapper {
  overflow-x: auto;
}
.vt-sort:before{
    font-family: FontAwesome;
    padding-right: 0.5em;
    width: 1.28571429em;
    display: inline-block;
    text-align: center;
}
</style>
