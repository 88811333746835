import Vue from 'vue'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faFileDownload, faUsers, faEye, faPlus, faEdit, faTrashAlt, faSave, faEraser, faTimes, 
  faCalendarPlus, faSortUp, faSortDown, faEnvelope, faUnlink, faSignOutAlt, faSearchLocation, faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import SmartTable from 'vuejs-smart-table'

require('./assets/sass/main.scss');

library.add(faUserSecret)
library.add(faFileDownload)
library.add(faUsers)
library.add(faEye)
library.add(faPlus)
library.add(faEdit)
library.add(faTrashAlt)
library.add(faSave)
library.add(faEraser)
library.add(faTimes)
library.add(faCalendarPlus)
library.add(faSortUp)
library.add(faSortDown)
library.add(faEnvelope)
library.add(faUnlink)
library.add(faSignOutAlt);
library.add(faSearchLocation);
library.add(faArrowLeft);

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(SmartTable)

Vue.directive('title', {
  inserted: (el, binding) => document.title = binding.value,
  update: (el, binding) => document.title = binding.value
})


new Vue({
  render: h => h(App),
}).$mount('#app')
